import { graphql } from 'gatsby'
import * as React from "react"
import FindDreamJob from "../components/careers/finddreamjob"
import JobDescription from "../components/careers/jobdescription"
import JobOpenings from "../components/careers/jobopenings"
import JobSearchForm from "../components/careers/jobsearchform"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"


export const query = graphql`
    query($jobslug: String, $language: String!) {
    contentfulJobs(jobSlug: { eq: $jobslug }, node_locale: {eq: "en-US"} ) {
      jobSlug
      title
      urgent
      description {
        description
      }
      benefits {
        benefit
      }
      benefitsDescription {
        benefitsDescription
      }
      requirements {
        requirements
      }
      ourclient {
        ourclient
      }
      locations {
        id
        slug
        name
        shortName
        country {
          region{
            shortName
          }
        }
      }
    }
    
    allContentfulJobs(
      filter: { locations: { elemMatch: { slug: { ne: null }, node_locale: {eq: "en-US"} } } }
      sort: { fields:title, order: ASC }
      limit: 5
    ) {
      nodes {
        id
        jobSlug
        title
        updatedAt(formatString: "MM/DD/YY")
        remote
        office
        urgent
        description {
          description
        }
        locations {
          id
          slug
          name
          shortName
        }
      }
    }

    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

const JobDescriptionPage = ({ jobslug, data, location }) => {
  const pageTitle = "Job Opportunities"
  const pageDescription = "Look at our Job Opportunities"
  const lang = 'EN'
  const meta = []

  const breadcrumbMap = [
    { title: "Careers", url: "/careers/" },
    { title: "Locations", url: "/careers/locations/" },
    { title: jobslug, url: "#" }
  ]

  const {
    title,
    description,
    requirements,
    benefits,
    ourclient,
    benefitsDescription,
    locations,
    urgent
  } = data.contentfulJobs

  const {
    nodes: jobs
  } = data.allContentfulJobs;

  let benefitList = benefits.map((item) => (item["benefit"]));
  let locationsList = locations.map((item) => (item["country"].region.shortName)).filter((v, i, a) => a.indexOf(v) === i);
  
  const jobDetails = {
    title: title,
    location: locationsList,
    url: jobslug,
    ourclient: ourclient ? ourclient.ourclient : null,
    description: description ? description.description : null,
    requirements: requirements ? requirements.requirements : "",
    benefitsDescription: benefitsDescription ? benefitsDescription.benefitsDescription : null,
    benefits: benefitList,
    additional: benefitsDescription.benefitsDescription,
    urgent: urgent
  };

 const jobsList = jobs.map( ({jobSlug, title, locations, description, remote, relocation, office, urgent }) => (
  {
    title: title,
    location: locations.map((item) => (item["shortName"])).join(" / "),
    jobLocation: locations.map((item) => (item["name"])).join(" / "),
    url: "/careers/jobs/"+jobSlug+"/",
    urgent: urgent,
    tags: {"remote":remote,"office":office,"relocation":relocation},
    introduction: description ? description.description.slice(0,220) + "..." : ""
}
 ));

 const jobLocation = location.search.split('=')[1];

  return (
    <>
      <Layout breadcrumbMap={breadcrumbMap}>
      <div className="max-container-pages">
        <Seo title={pageTitle} description={pageDescription} lang={lang} meta={meta} />
        <JobSearchForm></JobSearchForm>
        <JobDescription jobDetails={jobDetails} jobLocation={jobLocation}></JobDescription>
        <JobOpenings jobs={jobsList} jobLocation={jobLocation}></JobOpenings>
        <FindDreamJob></FindDreamJob>
      </div>
      </Layout>
    </>
  )
}

export default JobDescriptionPage
